import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';

import BlockNavigation from 'stride-core-ui/block_navigation';
import ErrorMessage from 'components/error_message';
import Dialog from 'components/dialog';
import Controls from 'components/controls';
// import SelectionsSubmit from 'components/selections_submit';
import Grid, {
  Title,
  Selectable
} from 'components/results_grid';

import {
  lodgingShape
} from 'reducers/selections';
import Checkbox from 'components/results_grid/selectable/Checkbox';
import styles from './styles';
import Api from '../../config/api';
import Image from '../locations_search/image';
import Slider from 'components/slider/Slider';
import Card from 'components/card';
import { duration } from 'moment';
import Header from 'components/header';
import threeStars from "assets/images/threeStars.png";
import fourStars from "assets/images/fourStars.png";
import fiveStars from "assets/images/fiveStars.png";
import ReactGA from 'react-ga4';

const BLOCKED_ROUTES = [{
  pathname: [
    '/signin',
    '/home-country',
    '/budget',
    '/completed'
  ],
  exact: false
}];
const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

// const gradient = 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 100%)';

const LodgingType = ({ collection, select, locations, dates, travelers, updateAmount, data }) => {

  const getSliderValue = () => {
    if (name === 'Standard') {
      setSliderValue(0);
    }
    if (name === 'Premium') {
      setSliderValue(54);
    }
    if (name === 'Luxury') {
      setSliderValue(100);
    }

  };

  const [sliderValue, setSliderValue] = useState(50);
  const [SliderMax, setSliderMax] = useState(0);
  const [SliderMin, setSliderMin] = useState(0);
  const [SliderAvg, setSliderAvg] = useState(0);
  const ids = locations.map(location => location.id);
  const setLodgingType = useCallback((data) => select(data.name));
  // const [options, setOptions] = useState([]);
  const [options, setOptions] = useState([
    // { id: 98, name: "Camping", image_url: "https://static.travelstride.com/store/main_image/168864/attachment/94c9156d70d293edecefef0aef514660.jpg" },
    { id: 96, name: "Standard", image: threeStars, description: 'Basic, comfortable, and affordable accommodations.' },
    { id: 95, name: "Premium", image: fourStars, description: 'Upscale hotels with enhanced amenities and style.' },
    { id: 94, name: "Luxury", image: fiveStars, description: 'Top-tier, luxurious hotels with premium services.' }
  ]);



  const getTitle = (dates, collection, travelers) => {
    let duration = '';
    if (dates.kind === 'exact' && dates.startDate && dates.endDate) {
      duration = dates.startDate && dates.endDate ? dates.endDate.diff(dates.startDate, 'days') : '';
    }
    if (dates.kind === 'approx' && dates.duration) {
      duration = dates.duration.value;
    }
    if (dates.kind === 'duration' && dates.duration) {
      duration = dates.duration.value;
    }
    let stars = ''
    if (collection == 'Standard') {
      stars = 'standard';
    }
    if (collection == 'Premium') {
      stars = 'premium';
    }
    if (collection == 'Luxury') {
      stars = 'luxury';
    }
    let numTravelers = ''
    if (travelers && travelers.travelersNumber) {
      numTravelers = travelers.travelersNumber.value;
    }

    return <><span>{numTravelers} travelers</span> for <span>{duration} days</span> staying in <span>{stars}</span> accommodations</>;
  };

  const getDuration = (dates) => {
    let duration = '';
    if (dates.kind === 'exact' && dates.startDate && dates.endDate) {
      duration = dates.startDate && dates.endDate ? dates.endDate.diff(dates.startDate, 'days') : '';
    }
    if (dates.kind === 'approx' && dates.duration) {
      duration = dates.duration.value;
    }
    if (dates.kind === 'duration' && dates.duration) {
      duration = dates.duration.value;
    }

    return duration;
  };

  const getTravelersNumber = (travelers) => {
    let numTravelers = ''
    if (travelers && travelers.travelersNumber) {
      numTravelers = travelers.travelersNumber.value;
    }

    return numTravelers;
  };

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Accomodation page' });

    Api.post('/countries/maxBudgetVIP', {
      ids: ids
    })
      .then(({ json }) => {
        setSliderMax(json.data.avgMinBudgetLuxuryPerDay);
        setSliderMin(json.data.avgMinBudgetStandardPerDay);
        setSliderAvg(json.data.avgMinBudgetPremiumPerDay);
      })
  }, []);

  useEffect(() => {
    let amount = 0;
    if (collection == 'Standard') {
      amount = SliderMin * getDuration(dates) * getTravelersNumber(travelers)
    }
    if (collection == 'Premium') {
      amount = SliderAvg * getDuration(dates) * getTravelersNumber(travelers)
    }
    if (collection == 'Luxury') {
      amount = SliderMax * getDuration(dates) * getTravelersNumber(travelers)
    }
    if (amount > 0) {
      updateAmount({
        ...data,
        amount
      });
    }
  }, [collection]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const getPriceRange = () => {
    let priceRange = '';
    if (SliderMin && SliderMax) {
      priceRange = formatCurrency((SliderMin * getTravelersNumber(travelers) * getDuration(dates)) * 0.6) + ' - ' + formatCurrency((SliderMax * getTravelersNumber(travelers) * getDuration(dates)) * 0.6);
    }
    return priceRange;
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header isLastStep={false} isFirstStep={false} />
      <Dialog
        title="What type of accommodations are you looking for?"
      >
        <span className={styles.title}>
          {getTitle(dates, collection, travelers)}
        </span>
        {/* {screenSize.width >= 730 ? (
          <div class='w-3/4 mx-auto'>
            <Slider
              noStar
              initialValue={sliderValue}
              min={SliderMin * getDuration(dates) * getTravelersNumber(travelers)}
              max={1000 * getDuration(dates) * getTravelersNumber(travelers)}
              avg={SliderAvg * getDuration(dates) * getTravelersNumber(travelers)}
              setSliderValue={setSliderValue}
            />
          </div>
        ) : (
          <div className={styles.avg}>
            Avg: {formatCurrency(SliderAvg * getDuration(dates) * getTravelersNumber(travelers))}
          </div>
        )} */}
        <div class='mx-auto text-center'>
          <span className={styles.price}>Total price estimate: {getPriceRange()}</span>
        </div>


        <div class={styles.cardContainer}>
          {
            options.map((item) => {
              const selected = collection === item.name;
              return (
                <Card data={item} name={item.name} onSelect={setLodgingType} isActive={selected} setSliderValue={setSliderValue} description={item.description} image={item.image} />
              );
            })
          }
        </div>
        {screenSize.width >= 400 && (
          <span className={styles.note}>
            Note: These are approximate averages based on typical costs in popular cities. Prices may vary based on the time of year, seasons, and other variables.
          </span>
        )}

        <BlockNavigation
          to={BLOCKED_ROUTES}
          disabled={collection.length !== 0}
          autoRelease={false}
        >
          <ErrorMessage>Please select one ore more options above</ErrorMessage>
        </BlockNavigation>

        <Controls
          prev={`/when-will-you-be-traveling${location.search}`}
          next={`/what-kind-of-trip-are-you-looking-for${location.search}`}
          nextDisabled={collection.length === 0}
          buttonLabel={'Next'}
        />
      </Dialog>
    </>
  );
};
LodgingType.propTypes = {
  collection: lodgingShape.isRequired,
  select: T.func.isRequired
};

export default LodgingType;
