import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";

import styles from "./Controls.module.scss";

const Controls = ({
  prev,
  next,
  nextDisabled,
  onNextClick,
  asButton,
  buttonLabel,
}) => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={styles.root}>
      {prev ? (
        <Button
          as={Link}
          kind={"secondary"}
          to={prev}
          size={screenSize.width <= 768 ? "small" : "medium"}
          className={styles.previous}>
          {/* <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 1.48679L6.41129 -6.94447e-08L-2.62268e-07 6L6.41129 12L8 10.5132L3.17742 6L8 1.48679Z"
            fill="#979797"
          />
        </svg> */}
          {"Previous"}
        </Button>
      ) : null}
      <div className={styles.placeholder} />
      {next ? (
        <Button
          onClick={onNextClick}
          as={asButton || nextDisabled ? "button" : Link}
          to={nextDisabled ? "" : next}
          className={styles.next}
          disabled={nextDisabled}
          size={screenSize.width <= 768 ? "small" : "medium"}
          id="next-button">
          {buttonLabel || "Next"}
        </Button>
      ) : null}
    </div>
  );
};
Controls.propTypes = {
  prev: T.string,
  next: T.string,
  nextDisabled: T.bool,
};
Controls.defaultProps = {
  prev: null,
  next: null,
  nextDisabled: false,
};

export default Controls;
