import React, { useCallback } from 'react';
import T from 'prop-types';
import { specificWishesShape } from 'reducers/selections';

import styles from './styles';

const placeholder =
  "Start typing and give us your tell all for this trip!"

const SpecificWishesForm = ({ data, select }) => {
  const onChange = useCallback((e) => {
    select({ text: e.target.value });
  }, []);

  return (
    <div className={styles.root}>
      <textarea
        placeholder={placeholder}
        className={styles.textarea}
        onChange={onChange}
        value={data.text}
      />
    </div>
  );
};
SpecificWishesForm.propTypes = {
  data: specificWishesShape.isRequired,
  select: T.func.isRequired
};
export default SpecificWishesForm;
