import React from 'react'
import cs from 'classnames'
import styles from './LoaderQuotes.module.scss'

const LoaderQuotes = ({ author, quoteText }) => {
  const authorName = author
  const quote = quoteText

  return (
    <div className={cs(styles.loaderQuotes)}>
      <div className={styles.quote}>&quot;{quote}&quot;</div>
      <div className={styles.author}>{authorName}</div>
    </div>
  )
}

export default LoaderQuotes
