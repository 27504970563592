import React, { useEffect, useState } from 'react'
import cs from 'classnames'
import styles from './GlobeLoader.module.scss'
import QuoteList from './QuoteList'
import LoaderQuotes from './LoaderQuotes'
import GlobeLoaderHeader from './GlobeLoaderHeader'

const GlobeLoader = ({active}) => {
  const getRandomQuotes =
    QuoteList[Math.floor(Math.random() * QuoteList.length)]
  const quoteAuthor = getRandomQuotes.Author
  const quoteText = getRandomQuotes.Quote
  const [showQuote, setQuote] = useState(true)
 
  return active ? (
    <div className={cs(styles.globeLoader, styles.activeLoader)}>
      <GlobeLoaderHeader />
      <div className={styles.globeLoaderContainer}>
        <div className={styles.globeIcon} />
        {showQuote && (
          <LoaderQuotes
            author={quoteAuthor}
            className={styles.LoaderQuotes}
            quoteText={quoteText}
          />
        )}
      </div>
    </div>
  ) : null
}

export default GlobeLoader
