import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import T from 'prop-types';
import BlockNavigation from 'stride-core-ui/block_navigation';
import ErrorMessage from 'components/error_message';
import Dialog from 'components/dialog';
import Controls from 'components/controls';
import { locationShape } from 'reducers/locations/locationsReducer';
import { useHistory } from 'react-router-dom';
// import AsyncSelect from 'react-select/async';
import Checkbox from 'stride-core-ui/checkbox';
import { ReactComponent as CheckMark } from 'assets/images/check.svg';
import { customSelectStyles, customSelectTheme } from 'components/select/config';
import { ValueContainer } from 'components/select';
import Api from '../../config/api';
import styles from './styles.module.scss';
import Button from '../../stride-core-ui/button';
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import Chip from 'components/chip';
import Header from 'components/header';
import ReactGA from 'react-ga4';

const BLOCKED_ROUTES = [{
  pathname: [
    '/who-is-traveling',
    '/when-will-you-be-traveling',
    '/what-kind-of-trip-are-you-looking-for',
    '/what-type-of-lodging-do-you-want',
    '/signin',
    '/home-country',
    '/budget',
    '/completed'
  ],
  exact: false
}];

const LocationsSearch = ({
  fetchLocations,
  collection,
  select,
  fetchingQuery,
  selectedLocations,
  moreSelectedLocations,
  reset,
  location
}) => {
  const history = useHistory();
  const [allMoreCountriesSelected, setAllMoreCountries] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [urlLocation] = useState(() => {
    const urlParams = new URLSearchParams(location.search || '');

    return urlParams.get('location') || '';
  });
  const [selectOptions, setSelectOptions] = useState([]);
  const [continent, setContinent] = useState([]);
  const [selectedContinent, setSelectedContinent] = useState([]);
  const [optionsLoading, setLoading] = useState(false);
  const [buttonIsEnabled, setButtonEnable] = useState(false);
  const onFavoriteCountryInputChange = (e) => {
    const values = Array.isArray(e)
      ? e.map(({ value: id, label: countryName }) => ({ id, countryName, slug: id }))
      : [];
    select(values, 'locations');
    // select(values.slice(1), 'moreLocations');

    // if (Array.isArray(e)) {
    //   const [firstValue, ...restValues] = e;
    //   select([firstValue], 'locations');
    //   select(restValues, 'moreLocations');
    // } else {
    //   select([], 'locations');
    //   select([], 'moreLocations');
    // }
    // const values = e !== null && e !== undefined ? [e].map(({ value: id, label: countryName }) => ({ id, countryName, slug: id })) : [];
    // select(values, 'locations');
  };

  const onCountryChange = (e) => {
    const values = Array.isArray(e)
      ? e.map(({ value: id, label: countryName }) => ({ id, countryName, slug: id }))
      : [];
    select(values, 'moreLocations');
  };

  const onChangeThis = (event, target) => {
    setButtonEnable(target);
    select(target ? 'Yes' : 'No', 'skipCountry');
  };

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Destination page' });

    setLoading(true);
    Api.get('/countries')
      .then(({ json }) => {
        const countriesData = json?.data?.continents?.map(({
          continent_en_name,
          countries,
          continent_id
        }) => ({
          id: continent_id,
          label: continent_en_name,
          options: countries.map(({
            country_en_name: label,
            country_id: value
          }) => ({
            value,
            label
          }))
        }));
        setSelectOptions(countriesData);
      })
      .finally(() => {
        setLoading(false);
      });

    Api.get('/continents')
      .then(({ json }) => {
        setContinent(json?.data);
      });

    return reset;
  }, []);

  /**
   * If there is a 'location' param in the query, try to search this location
   * and check it for the user
   */
  useEffect(() => {
    if (urlLocation && urlLocation === fetchingQuery && collection && collection.length === 1) {
      const searchedLocation = collection.find(({ slug }) => slug === urlLocation);

      if (!searchedLocation) {
        return;
      }

      if (!selectedLocations.some((i) => i.slug === urlLocation)) {
        select(searchedLocation, 'locations');
      }
    }
  }, [urlLocation, fetchingQuery]);

  useEffect(() => {
    if (urlLocation) {
      fetchLocations(urlLocation);
    }
  }, [urlLocation]);

  const handleContinentClick = (value) => {
    setSelectedContinent((prevSelected) => prevSelected.includes(value) ? prevSelected.filter(item => item !== value) : [...prevSelected, value]);
    // setSelectedContinent((prevSelected) => [...prevSelected, value]);
  };

  function getOptions() {
    const filteredOptions = selectedContinent.length === 0 ? selectOptions : selectOptions.filter((continent) => selectedContinent.includes(continent.id));
    return filteredOptions;
  }

  useEffect(() => {
    if (urlLocation === 'ustoa') {
      const values = [{
        id: 'united-states',
        countryName: 'United States',
        slug: 'united-states'
      }];
      select(values, 'locations');
    }
  }, [urlLocation]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <Header isLastStep={false} isFirstStep={false} />
      <Dialog title="Where do you want to go?">
        <span className={styles.title}>
          The perfect trip starts with the destination. So, spill the beans! What's on your bucket list for this trip?
        </span>
        <br />
        {screenSize.width >= 768 && (
          <div className={styles.selectedTags}>
            {continent.map(({
              continent_id: value,
              continent_en_name: label
            }) => (
              <Chip id={value} label={label} isActive={selectedContinent.includes(value)} handleClick={handleContinentClick} />
            ))}
          </div>
        )}
        <br />
        <div class={styles.selectContainer}>
          <Select
            closeMenuOnSelect={false}
            options={getOptions()}
            isLoading={optionsLoading}
            isMulti
            hideSelectedOptions={false}
            placeholder="Destination country"
            onChange={onFavoriteCountryInputChange}
            value={selectedLocations.map(({ id: value, countryName: label }) => ({ value, label }))}
            theme={customSelectTheme}
            styles={customSelectStyles}
            isClearable
          />
        </div>
        {!buttonIsEnabled && (
          <BlockNavigation
            to={BLOCKED_ROUTES}
            disabled={!buttonIsEnabled && selectedLocations.length !== 0}
            autoRelease={false}
          >
            <ErrorMessage>You can select maximum 5 countries</ErrorMessage>
          </BlockNavigation>
        )}
        {/* {selectedLocations.length > 0 && (
          <>
            <br />
            Add up to four points of interest you would like to visit on your trip
            <br />
            <br />
            {isOpen && (
              <div className={styles.confirmFlex}>
                <Button className={styles.confirm} size="sm">
                  confirm
                </Button >
              </div>
            )}
            <Select
              options={getOptions()}
              isMulti
              isLoading={optionsLoading}
              hideSelectedOptions={false}
              placeholder="Search / Dropdown list of pois"
              components={{ ValueContainer }}
              onChange={onCountryChange}
              value={moreSelectedLocations.map(({ id: value, countryName: label }) => ({ value, label }))}
              closeMenuOnSelect={false}
              theme={customSelectTheme}
              styles={customSelectStyles}
              onMenuOpen={() => setIsOpen(true)}
              onMenuClose={() => setIsOpen(false)}
            />
          </>
        )} */}

        <br />

        {/* <Checkbox
          onChange={onChangeThis}
          name="skipcountry"
          label="I'm not sure yet"
        /> */}

        <Controls
          // prev={`/${location.search}`}
          next={`/who-is-traveling${location.search}`}
          nextDisabled={!buttonIsEnabled && selectedLocations.length === 0}
          buttonLabel="Next"
        //  || moreSelectedLocations.length === 0}
        />
      </Dialog>
    </>
  );
};
LocationsSearch.propTypes = {
  fetchLocations: T.func.isRequired,
  fetchingQuery: T.string.isRequired,
  select: T.func.isRequired,
  // fetching: T.bool.isRequired,
  selectedLocations: T.arrayOf(locationShape).isRequired,
  collection: T.arrayOf(locationShape).isRequired,
  reset: T.func.isRequired,
  location: T.shape({ search: T.string })

};

LocationsSearch.defaultProps = {
  location: {}
};

export default LocationsSearch;
