import React, { PureComponent } from "react";
// import T from 'prop-types';
// import Button from 'stride-core-ui/button';
import Input from "stride-core-ui/input";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./styles";
import Checkbox from "stride-core-ui/checkbox";
import Select from "react-select";
import {
  customSelectStylesContactDetails,
  customSelectTheme,
} from "components/select/config";
import Api from "config/api";

const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "can't be blank";
  } else if (!emailRegexp.test(values.email)) {
    errors.email = "invalid email address";
  }

  ["firstname"].forEach((key) => {
    if (!values[key]) {
      errors[key] = "can't be blank";
    }
  });

  ["lastname"].forEach((key) => {
    if (!values[key]) {
      errors[key] = "can't be blank";
    }
  });

  return errors;
};

class ContactDetailsForm extends PureComponent {
  constructor() {
    super();
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      countries: [],
    };
  }

  componentDidMount() {
    Api.get("/countries").then(({ json }) => {
      let countriesData = json?.data?.continents?.map(
        ({ continent_en_name, countries, continent_id }) => ({
          id: continent_id + 1,
          label: continent_en_name,
          options: countries.map(
            ({ country_en_name: label, country_id: value }) => ({
              value,
              label,
            })
          ),
        })
      );
      const topCountries = {
        id: 0,
        label: "",
        options: [
          { value: "United States", label: "United States" },
          { value: "United Kingdom", label: "United Kingdom" },
          { value: "Canada", label: "Canada" },
          { value: "Australia", label: "Australia" },
        ],
      };
      const topCountriesDivider = {
        id: 1,
        label: "____________________",
      };
      countriesData.push(topCountries);
      this.setState({ countries: countriesData.sort((a, b) => a.id - b.id) });
    });
  }

  onSubmit(e) {
    this.props.setContactDetails(e);
  }

  renderForm({
    handleChange,
    values,
    handleBlur,
    touched,
    errors: clientValidationErrors,
    handleSubmit,
  }) {
    const { countries } = this.state;
    const errors = {
      ...clientValidationErrors,
    };

    let conctactPreferences = [
      {
        label: "Email",
        value: "contactemail",
      },
      {
        label: "Call Me",
        value: "call_me",
      },
      {
        label: "Text Me",
        value: "text_me",
      },
      {
        label: "WhatsApp",
        value: "whatsapp",
      },
    ];

    const PLANNING_STAGE_OPTIONS = [
      { value: "research_planning", label: "Research/Planning" },
      { value: "arrowing_choices", label: "Narrowing choices" },
      { value: "booking", label: "Ready to book" },
    ];

    const extractCountryCode = (index) => {
      const reactTelInput = document.querySelector(".react-tel-input");

      const selectedFlagElement =
        reactTelInput?.querySelector(".selected-flag");
      const titleAttribute = selectedFlagElement?.getAttribute("title");
      const codeIndex = titleAttribute?.indexOf("+");

      if (codeIndex && codeIndex > 0) {
        // Extract the country code starting from the '+'
        const countryCode = titleAttribute?.substring(codeIndex + index).trim();
        return countryCode;
      }
    };

    const onChange = (e) => {
      const values = Array.isArray(e) ? e.map(({ value: value }) => value) : [];
      handleChange({
        target: {
          name: "contact_preferences",
          value: values,
        },
      });

      // const slected = conctactPreferences.find(
      //   (item) => item.name === name
      // ).key;
      // if (value) {
      //   handleChange({
      //     target: {
      //       name: "contact_preferences",
      //       value: [...values.contact_preferences, slected],
      //     },
      //   });
      // } else {
      //   handleChange({
      //     target: {
      //       name: "contact_preferences",
      //       value: values.contact_preferences.filter(
      //         (item) => item !== slected
      //       ),
      //     },
      //   });
      // }
    };

    const onHomeCountrySelect = (e) => {
      handleChange({
        target: {
          name: "homeCountry",
          value: e.label,
        },
      });
    };

    const onChangePhone = (value) => {
      handleChange({
        target: {
          name: "phone",
          value: value.replace(extractCountryCode(1), ""),
        },
      });
      handleChange({
        target: {
          name: "countrycode",
          value: extractCountryCode(0),
        },
      });
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.root}>
          <div>
            <span className={styles.label}>First Name *</span>
            <Input
              required
              placeholder="First Name"
              name="firstname"
              isInvalid={touched.firstname && Boolean(errors.firstname)}
              error={errors.firstname}
              className={styles.input}
              autoComplete="given-name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstname}
            />
          </div>
          <div>
            <span className={styles.label}>Last Name *</span>
            <Input
              required
              placeholder="Last Name"
              name="lastname"
              isInvalid={touched.firstname && Boolean(errors.lastname)}
              error={errors.lastname}
              autoComplete="family-name"
              onBlur={handleBlur}
              className={styles.input}
              onChange={handleChange}
              value={values.lastname}
            />
          </div>
          <div>
            <span className={styles.label}>Phone Number *</span>
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
                autoComplete: "Phone Number",
              }}
              country={"us"}
              onChange={onChangePhone}
              values={values.phone}
              onBlur={handleBlur}
              preferredCountries={["us", "ca", "gb", "au"]}
              error={errors.phone}
              inputClass={styles.phoneInput}
            />
          </div>
          <div>
            <span className={styles.label}>Email *</span>
            <Input
              required
              type="email"
              placeholder="Your best email address"
              className={styles.input}
              name="email"
              isInvalid={touched.email && Boolean(errors.email)}
              error={errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
            />
          </div>
          <div>
            <span className={styles.label}>Home country*</span>
            <Select
              className={styles.wFull}
              isSearchable={true}
              onChange={onHomeCountrySelect}
              options={countries}
              placeholder="Trip planning stage"
              styles={customSelectStylesContactDetails}
              theme={customSelectTheme}
            />
          </div>
          <div>
            <span className={styles.label}>
              How can we reach you? <span>Choose all that apply</span>
            </span>
            <Select
              className={styles.wFull}
              onChange={onChange}
              options={conctactPreferences}
              placeholder="How can we reach you?"
              styles={customSelectStylesContactDetails}
              isMulti
              isClearable
              theme={customSelectTheme}
              closeMenuOnSelect={false}
            />
          </div>

          <div className={styles.wrong}>{errors.base}</div>
        </div>
        {this.props.children}
      </form>
    );
  }

  render() {
    const initialValues = {
      firstname: this.props.data?.firstName ?? "",
      lastname: this.props.data?.lastName ?? "",
      email: this.props.data?.email ?? "",
      phone: "",
      countrycode: "",
      contact_preferences: "",
    };

    return (
      <Formik
        ref={this.props.formRef}
        innerRef={this.props.formRef}
        initialValues={initialValues}
        validate={validate}
        onSubmit={this.onSubmit}
        enableReinitialize
        render={this.renderForm}
      />
    );
  }
}

export default ContactDetailsForm;
