import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { datesShape } from 'reducers/selections';
import RadioInput from 'stride-core-ui/radio_input';
import Select from 'react-select';
import moment from 'moment';
import { customSelectStyles, customSelectTheme } from 'components/select/config';


import styles from './styles';

const DEPARTURE_OPTIONS = [];
const DEPARTURE_OPTIONS_YEAR = [];
for (let i = 0; i < 12; i += 1) {
  const date = moment().add(i, 'months').startOf('month');
  const year = moment().add(i, 'year').year()
  const label = date.format('MMMM')
  DEPARTURE_OPTIONS.push({
    value: date,
    label
  });
  DEPARTURE_OPTIONS_YEAR.push({
    value: year.toString(),
    label: year.toString(),
  })
}

const DURATION_OPTIONS = [];
for (let i = 1; i <= 30; i += 1) {
  DURATION_OPTIONS.push({
    label: `${i} day${i > 1 ? 's' : ''}`,
    value: i
  });
}

const ApproxDatesSelect = ({ data, select }) => {
  const onChange = useCallback(() => {
    select({ kind: 'approx', departureMonth: null, duration: null, departureYear: null });
  });

  const onDepartureSelect = useCallback((departureMonth) => {
    select({
      ...data,
      departureMonth
    });
  });

  const onDurationSelect = useCallback((duration) => {
    select({
      ...data,
      duration
    });
  });

  const onYearSelect = useCallback((departureYear) => {
    select({
      ...data,
      departureYear
    });
  });

  const active = data.kind === 'approx';

  return (
    <div className={styles.inputRoot}>
      <RadioInput
        label="I have approximate dates"
        name="approx"
        onChange={onChange}
        checked={active}
      />
      {
        active
          ? (
            <div className='flex flex-col w-full ms-16 items-center'>
              <div className={styles.inputTitle}>Select the period that apply:</div>
              <div className={styles.inputs}>
                <Select
                  value={data.departureMonth}
                  onChange={onDepartureSelect}
                  options={DEPARTURE_OPTIONS}
                  className={styles.select}
                  placeholder="Months"
                  isSearchable={false}
                  styles={customSelectStyles}
                  theme={customSelectTheme}
                />
                <Select
                  isSearchable={false}
                  onChange={onYearSelect}
                  options={DEPARTURE_OPTIONS_YEAR}
                  placeholder='Year'
                  className={styles.select}
                  styles={customSelectStyles}
                  theme={customSelectTheme}
                  value={data.departureYear}
                />
                <Select
                  value={data.duration}
                  onChange={onDurationSelect}
                  options={DURATION_OPTIONS}
                  placeholder="Duration"
                  className={styles.select}
                  isSearchable={false}
                  styles={customSelectStyles}
                  theme={customSelectTheme}
                />
              </div>
            </div>
          ) : null
      }
    </div>
  );
};
ApproxDatesSelect.propTypes = {
  data: datesShape.isRequired,
  select: T.func.isRequired
};
export default ApproxDatesSelect;
