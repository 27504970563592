import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import Select from 'react-select';
import { datesShape } from 'reducers/selections';
import RadioInput from 'stride-core-ui/radio_input';
import { customSelectStyles, customSelectTheme } from 'components/select/config';
import styles from './styles';

const OPTIONS = [];
for (let i = 1; i <= 30; i += 1) {
  OPTIONS.push({
    label: `${i} day${i > 1 ? 's' : ''}`,
    value: i
  });
}

const DurationSelect = ({ data, select }) => {
  const onChange = useCallback(() => {
    select({ kind: 'duration', startDate: null, endDate: null });
  });

  const setDuration = useCallback((duration) => {
    select({
      ...data,
      duration
    });
  }, [data]);

  const active = data.kind === 'duration';

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.inputRoot}>
      <RadioInput
        label="I haven't decided yet"
        name="duration"
        onChange={onChange}
        checked={active}
      />
      {
        active
          ? (
            <div className={styles.inputs}>
              <Select
                value={data.duration}
                onChange={setDuration}
                options={OPTIONS}
                placeholder="Duration"
                className={styles.select}
                isSearchable={false}
                styles={customSelectStyles}
                theme={customSelectTheme}
              />
            </div>
          ) : null
      }
    </div>
  );
};
DurationSelect.propTypes = {
  data: datesShape.isRequired,
  select: T.func.isRequired
};

export default DurationSelect;
