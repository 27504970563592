/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import Dialog from 'components/dialog';
import cs from 'classnames';
import { emitGTMEvent } from 'config/gtm';
import { emitGAEvent } from 'config/ga';
import { useHistory } from 'react-router';
// import { ReactComponent as DocumentIcon } from 'assets/images/document.svg';
// import { ReactComponent as CheckIcon } from 'assets/images/check_circle.svg';
// import { ReactComponent as ChatIcon } from 'assets/images/chat.svg';
// import { ReactComponent as PlanIcon } from 'assets/images/plan.svg';
import Button from 'stride-core-ui/button';
import styles from './styles';
import { connect } from 'react-redux';
import GlobeLoader from 'components/globe-loader/GlobeLoader';
import { ReactComponent as ShareSvg } from 'assets/images/share.svg';
import { ReactComponent as EditSvg } from 'assets/images/edit.svg';
import Day from 'components/day';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import { Icon } from 'leaflet';
import { select } from 'redux-saga/effects';
import selections from 'sagas/selections';
import Api from '../../config/api';
import { set } from 'react-ga';
import L from 'leaflet';
import moment, { duration } from 'moment';
import { useLocation } from 'react-router-dom';
import StrideLogo from '../../components/header/logo';
import { ReactComponent as PassengerIcon } from 'assets/images/passenger.svg';
import Popup from 'reactjs-popup';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, EffectFade, Lazy, SwiperOptions } from 'swiper';
import ReactGa from 'react-ga4';
SwiperCore.use([Autoplay, EffectFade, Lazy])

const Step = ({
  children,
  current
}) => (
  <div className={cs(styles.step, { [styles.current]: current })}>
    {children}
  </div>
);
Step.propTypes = {
  children: T.node.isRequired,
  current: T.bool
};
Step.defaultProps = {
  current: false
};

const Completed = ({ submitting, selections }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  function getCenterCoordinate(coordinates) {
    // Create an array of L.LatLng objects
    const latLngs = coordinates.map(coord => L.latLng(coord.lat, coord.lng));

    // Create a bounding box that includes all the coordinates
    const bounds = L.latLngBounds(latLngs);

    // Get the center of the bounding box
    const center = bounds.getCenter();

    return center;
  }
  const [urlLocation] = useState(() => {
    const urlParams = new URLSearchParams(location.search || '');

    return urlParams.get('location') || '';
  });

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [coordinatesCenter, setCoordinatesCenter] = useState(null);
  const [data, setData] = useState({
    primary_country: '',
    budget: '',
    start_date: '',
    end_date: '',
    travelers_number: '',
    tripType: '',
    activities: [],
    description: '',
    highlights: [],
    title: '',
    startCity: '',
    endCity: '',
    duration: '',
    lodgingLevels: '',
    tripItinerary: []
  });

  // useEffect(() => {
  //   const eventData = {
  //     event: 'CustomTripFormSubmit',
  //     category: 'CustomTripFormSubmit',
  //     action: 'CustomTripFormSubmitAction',
  //     label: 'CustomTripFormSubmit'
  //   };
  //   emitGTMEvent(eventData);
  //   emitGAEvent(eventData);

  //   // To make it impossible to go back after submission
  //   history.push({
  //     pathname: 'completed'
  //   });
  //   const unlisten = history.listen((newLocation, action) => {
  //     if (action !== 'PUSH') {
  //       // Send user back if they try to navigate back
  //       history.go(1);
  //     }
  //   });

  //   return unlisten;
  // }, []);

  function formatJson(data) {
    return {
      primaryCountry: data.primary_country || '',
      additionalCountries: data.additional_countries || [],
      budget: data.budget || '',
      startDate: data.start_date || '',
      endDate: data.end_date || '',
      travelersNumber: data.travelers_number || '',
      tripType: data.trip_type || '',
      duration: data.duration || '',
      lodgingLevels: data.lodging_levels || [],
      tripItinerary: data.trip_itinerary || []
    };
  }

  useEffect(() => {
    setLoading(true);
    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Itinerary page' });
    const primaryCountry = urlParams.get('primary_country');
    const item_id = urlParams.get('itinerary');
    Api.get(`/custom_trip_request/${item_id}/?primary_country=${primaryCountry}`)
      .then(({ json }) => {
        console.log(json);
        setData(formatJson(json));
        getAllCoordinates(json.trip_itinerary);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  function getAllCoordinates(tripItinerary) {
    const coordinates = [];
    const coordinatesCenter = [];

    tripItinerary.forEach(item => {
      if (item.start_city_coordinates) {
        coordinates.push(item.start_city_coordinates);
        coordinatesCenter.push({
          lat: item.start_city_coordinates[0],
          lng: item.start_city_coordinates[1]
        });
      }
      if (item.end_city_coordinates) {
        coordinates.push(item.end_city_coordinates);
        coordinatesCenter.push({
          lat: item.end_city_coordinates[0],
          lng: item.end_city_coordinates[1]
        });
      }
    });
    // Create an array of L.LatLng objects
    const latLngs = coordinatesCenter.map(coord => L.latLng(coord.lat, coord.lng));

    // Create a bounding box that includes all the coordinates
    const bounds = L.latLngBounds(latLngs);

    // Get the center of the bounding box
    const center = bounds.getCenter();

    // Set the coordinates and center
    setCoordinates(coordinates);
    setCoordinatesCenter(center);
  }

  const customMarker = new Icon({
    iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const TripTypes = [{
    name: 'shared_tour',
    title: 'Shared Tour',
    description: 'Join other travelers shared group tours.'
  }, {
    name: 'partially_guided',
    title: 'Mixed',
    description: 'Private guides for highlights and shared group for other activities'
  }, {
    name: 'fully_guided',
    title: 'Private Guides',
    description: 'Have a local guide whenever possible.'
  }]

  const getTitle = (duration, lodging, travelersNumber, tripType) => {
    let stars = ''
    if (lodging && lodging[0]?.name == 'Standard') {
      stars = '3';
    }
    if (lodging && lodging[0]?.name == 'Premium') {
      stars = '4';
    }
    if (lodging && lodging[0]?.name == 'Luxury') {
      stars = '5';
    }
    let accommodations = TripTypes.find(option => option.name === tripType)?.title;

    return <> <span>{travelersNumber} travelers</span> for <span>{duration} days</span> staying in <span>{stars} star</span> accommodations - <span>{accommodations}</span></>;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const copyUrlToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      alert('URL copied to clipboard!');
      console.log(data);
    }).catch(err => {
      console.error('Failed to copy URL: ', err);
    });
  };

  const [open, setOpen] = useState(true);
  const closeModal = () => setOpen(false);

  const [images, setImages] = useState([]);

  useEffect(() => {
    let array = [];
    data.tripItinerary?.forEach((day) => {
      if (day.place_photo_url) {
        array.push(day.place_photo_url);
      }
    });
    setImages(array);
  }, [data])



  return (
    <>
      <div className='overflow-hidden'>
        <GlobeLoader active={loading} />
        <div className={styles.header}>
          <StrideLogo />
          <div className={styles.tripDataDiv}>
            <span className={styles.text}>
              {data.travelersNumber}
              {"  "}
              travelers
            </span>
            {data.duration &&
              <span className={styles.text}>
                {data.duration} days {"  "}
              </span>
            }
            {data.startDate && data.endDate &&
              <span className={styles.text}>
                {moment(data.startDate, "MM/DD/YYYY").format("MMM D YYYY")}
                {"  "}-{"  "}
                {moment(data.endDate, "MM/DD/YYYY").format("MMM D YYYY")}
              </span>
            }
            <span className={styles.travelers}>{TripTypes.find(option => option.name === data.tripType)?.title}</span>
          </div>
          <div className={styles.buttonDiv}>
            <div className={styles.priceDiv}>
              <span className={styles.price}>
                {formatCurrency(data.budget * 0.4)} - {formatCurrency(data.budget * 1.2)}
                <span> total estimate</span>
              </span>
              {/* <span className={styles.text}>Avg. $500 per person per day</span> */}
            </div>
            <span className={styles.estiamte}>This is a preliminary estimate, subject to change based on final itinerary.</span>
            <div className='flex gap-2'>
              <Button as="a" href="/my-custom-trip/get-quote" className={styles.fillBtn}>
                Get a quote
              </Button>
              {/* <Button as="a" href="/" className={styles.outlineBtn}>
              Customize
            </Button> */}
            </div>
          </div>
        </div>
        <div className={styles.contentDiv}>
          <div className={styles.content}>
            <div className={styles.titleDiv}>
              <h2>My {data.primaryCountry}{" "}{data.additionalCountries && data.additionalCountries[0]} Trip</h2>
              {images.length > 0 && (
                <Swiper
                  slidesPerView='auto'
                  spaceBetween={15}
                  loop
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.imageCon}>
                        <img src={image} />
                      </div>
                    </SwiperSlide>
                  ))}

                </Swiper>
              )}
              <span>This itinerary is a draft and will be reviewed by a travel expert. It may change based on your feedback and additional requests. Let us know if you have specific preferences or adjustments in mind.</span>
            </div>
            <div className={styles.dayDiv}>
              {data?.tripItinerary?.map((day, index) => (
                <Day key={index} id={index + 1} imageUrl={day.place_photo_url} startDate={moment(data.startDate, "MM/DD/YYYY").add(index, 'days').format("MM/DD/YYYY")} highlights={day.highlights} start_city={day.start_city} end_city={day.end_city} title={day.title} description={day.description} />
              ))}

            </div>
          </div>
          <div className={styles.map}>
            {!loading && !open &&
              <>
                <div className={styles.actionDiv}>
                  {/* <button className={styles.edit}>
                <EditSvg />
                Edit trip
              </button> */}
                  <button className={styles.share} onClick={copyUrlToClipboard}>
                    <ShareSvg />
                    Share trip
                  </button>
                </div>
                <div className={styles.mapContainer}>
                  <MapContainer center={coordinatesCenter} zoom={3} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }}>
                    {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
                    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" attribution='Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community' />
                    {coordinates?.map(marker => (
                      <>
                        <Marker position={marker} icon={customMarker} />
                      </>
                    ))}
                  </MapContainer>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <Popup
        open={open}
        modal
      >
        <div className={styles.modal}>
          <div onClick={closeModal} className={styles.overlay}></div>
          <div className={styles.modalContent}>
            <div className={styles.titleRow}>
              <h2>Check out your preliminary itinerary</h2>
              <button className={styles.close} onClick={closeModal}>
                &times;
              </button>
            </div>
            <p>
              Your request details will be reviewed, refined, and booked (if you choose) by a local travel expert. The price and inclusions may change based on your feedback and additional requests.
              <br />
              <br />
              Your trip designer will contact you via email within 2 business days with your trip quote. You can revise it with them.
              <br />
              <br />
              Until then, enjoy this snapshot as you continue to dream!
            </p>
            <Button onClick={closeModal} className={styles.closeModal}>
              Got it
            </Button>
          </div>
        </div>
      </Popup>
    </>
  );
};

Completed.propTypes = {
  submitting: Boolean
};

const mapStateToProps = state => ({ submitting: state.selections.submitting, selections: state.selections });

export default connect(mapStateToProps)(Completed);
