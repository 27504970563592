import React, { useCallback, useState } from 'react';
import T from 'prop-types';
import { DateRangePicker } from 'react-dates';
import { datesShape } from 'reducers/selections';
import RadioInput from 'stride-core-ui/radio_input';
import Moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import {
  lessThanSmallQuery
} from 'config/media';

import './styles.scss';

import styles from './styles';


const ExactDatesSelect = ({ data, select }) => {
  const onChange = useCallback(() => {
    select({ kind: 'exact', startDate: null, endDate: null });
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const setDates = useCallback(({ startDate, endDate }) => {
    select({ ...data, startDate, endDate });
  }, [data]);

  const active = data.kind === 'exact';
  const small = useMediaQuery({
    query: lessThanSmallQuery
  });

  const today = new Moment();

  return (
    <div className={styles.inputRoot}>
      <RadioInput
        label="I have specific dates"
        name="exact"
        onChange={onChange}
        checked={active}
      />

      {
        active
          ? (
            <div className=''>
              <div className={styles.calendarRoot}>
                <DateRangePicker
                  startDate={data.startDate}
                  startDateId="exact-start-date"
                  endDate={data.endDate}
                  endDateId="exact-end-date"
                  onDatesChange={setDates}
                  focusedInput={focusedInput}
                  onFocusChange={setFocusedInput}
                  readOnly
                  block
                  hideKeyboardShortcutsPanel
                  numberOfMonths={small ? 1 : 2}
                  isOutsideRange={(date) => date.isSameOrBefore(today)}
                />
              </div>
            </div>
          ) : null
      }
    </div>
  );
};
ExactDatesSelect.propTypes = {
  data: datesShape.isRequired,
  select: T.func.isRequired
};

export default ExactDatesSelect;
